<template>
  <div class="rankTop">
    <ul>
      <li
        v-for="(item, index) in pageList"
        :key="'rankTopItem' + index"
        :class="{ selected: item.selected }"
        @click="changeRankType(item, index)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "rankTop",
  props: ["list"],
  data() {
    return {
      pageList: [],
    };
  },
  methods: {
    changeRankType(item, index) {
      this.pageList.forEach((val, e) => {
        e == index
          ? this.$set(val, "selected", true)
          : this.$set(val, "selected", false);
      });

      this.$store.commit("rankingList/set_rankTopSelected", index);

      let params = {
        data: item,
        index: index,
      };

      this.$emit("changeRankType", params);
    },
  },
  mounted() {
    setTimeout(() => {
      this.pageList = this.list;
      this.pageList[0].selected = true;
    }, 0);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.rankTop {
  position: relative;
  width: 100%;
  padding: toPad(12) toPad(40) toPad(12) toPad(40);
  background: #fff;
  z-index: 2;

  ul {
    display: flex;
    width: 100%;
    align-items: flex-end;

    li {
      display: inline-block;
      font-size: toPad(18);
      line-height: 1;
      cursor: pointer;
      margin-right: toPad(22);
      opacity: 0.7;

      &.selected {
        font-size: toPad(26);
        opacity: 1;
        font-weight: bold;
      }
    }
  }
}
</style>
