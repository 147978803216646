<template>
  <section class="edp-common-content edp-rankingList--member">
    <div class="edp-rankingList--member__blank"></div>

    <div class="edp-rankingList--member__content">
      <swiper :options="swiperOptions" :ref="'mySwiper'">
        <swiper-slide
          v-for="(item, index) in mySwiper_list"
          :key="'mySwiper_rank' + index"
          class="edp-rank__swiper__slide"
        >
          <div class="edp-rank__swiper__slide__item bf-op">
            <section
              class="ersItem-title"
              :class="{ 'asc-title': paramsArray[index].colorRank === 'black' }"
            >
              <div class="ersItem-title__label">
                <label v-html="item.title"></label>
                <i @click="qes_click(index, item)"></i>
              </div>

              <em @click="sortThis(index)">{{ $t("rank.rankSort") }}</em>
            </section>

            <section
              class="ersItem-content"
              v-infinite-scroll="loadMoreList"
              :infinite-scroll-distance="10"
              :infinite-scroll-disabled="!moreLoadingPd"
              ref="rankScrollRef"
              v-show="item.list && item.list.length > 0"
            >
              <div style="width: 100%">
                <div
                  class="ersItem-content__item"
                  v-for="(itemListItem, itemListIndex) in item.list"
                  :key="'ersItemListItem' + itemListIndex"
                  :class="{ rankItemFirst: itemListIndex == 0 }"
                >
                  <div class="ersItem-content__sortNum">
                    {{ itemListIndex + 1 }}
                  </div>

                  <div class="ersItem-content__imgContent">
                    <img :src="itemListItem.photo || defaultHeadImage" alt="" />

                    <img
                      src="@/src/assets/rank/rank-one.png"
                      alt=""
                      class="ersItem-content__imgContent__first"
                      v-if="itemListIndex == 0"
                    />
                  </div>

                  <div class="ersItem-content__detail">
                    <em>{{
                      $i18n.locale === "cn"
                        ? itemListItem.cname || itemListItem.ename
                        : itemListItem.ename || itemListItem.cname
                    }}</em>
                    <p>
                      {{
                        $i18n.locale === "cn"
                          ? itemListItem.jobPositionCn ||
                            itemListItem.jobPosition
                          : itemListItem.jobPosition ||
                            itemListItem.jobPositionCn
                      }}
                    </p>
                  </div>

                  <div class="ersItem-content__numDetail">
                    <em>{{ itemListItem.rankCount }}</em>
                    <p v-if="item.numType === 'hour'">
                      {{ $t("rank.rankUnit_hour") }}
                    </p>
                    <p v-if="item.numType === 'time'">
                      {{ $t("rank.rankUnit_time") }}
                    </p>
                    <p v-if="item.numType === 'year'">
                      {{ $t("rank.rankUnit_year") }}
                    </p>
                    <p v-if="item.numType === 'person'">
                      {{ $t("rank.rankUnit_person") }}
                    </p>
                    <p v-if="item.numType === 'money'">
                      {{ $t("rank.rankUnit_money") }}
                    </p>
                    <p v-if="item.numType === 'score'">
                      {{ $t("rank.rankUnit_score") }}
                    </p>
                    <p v-if="item.numType === 'zNum'">
                      {{ $t("rank.rankUnit_zNum") }}
                    </p>
                    <p v-if="item.numType === 'day'">
                      {{ $t("rank.rankUnit_day") }}
                    </p>
                    <p v-if="item.numType === 'percent'">
                      {{ $t("rank.rankUnit_percent") }}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section
              class="ersItem-commingSoon"
              v-if="(!item.list || item.list.length == 0) && !subLoading"
            >
              {{ $t("rank.commingSoon") }}
            </section>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div
      class="rankCircle edp-rank-out__sk"
      id="memberRankCircle"
      v-show="rankTopSelected != 2"
    ></div>

    <van-overlay
      :show="subLoading"
      z-index="120"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <div class="loading-wrap-common">
        <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
          >&nbsp;</van-loading
        >
      </div>
    </van-overlay>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "memberRankList",
  created() {
    if (this.page_platform === "windows") {
      let windowWidth =
        document.documentElement.clientWidth || document.body.clientWidth;

      if (windowWidth > 1600) {
        this.swiperOptions.slidesPerView = 4.1;
      }
    }
  },
  data() {
    return {
      subLoading: false,
      swiperOptions: {
        slidesPerView: 3.1,
        spaceBetween: 20,
        pagination: {
          el: "#memberRankCircle",
          clickable: true,
        },
      },
      paramsArray: [],
      mySwiper_list: [],
      moreLoadingPd: false,

      pageNum: 1,
      pageSize: 20,
    };
  },
  computed: {
    ...mapState({
      rankTopSelected: (state) => state.rankingList.rankTopSelected,
      defaultHeadImage: (state) => state.login.defaultHeadImage,
      page_platform: (state) => state.login.page_platform,
    }),
  },
  methods: {
    ...mapActions({
      rank_meetTime: "rankingList/rank_meetTime",
      rank_printNum: "rankingList/rank_printNum",
      rank_meetCancel: "rankingList/rank_meetCancel",
      rank_taxi: "rankingList/rank_taxi",
      rank_edcMoney: "rankingList/rank_edcMoney",
      rank_phoneMoney: "rankingList/rank_phoneMoney",
      rank_travelInCountry: "rankingList/rank_travelInCountry",
      rank_travelOutCountry: "rankingList/rank_travelOutCountry",

      rank_inPush: "rankingList/rank_inPush",
      rank_workYear: "rankingList/rank_workYear",
      rank_studyNums: "rankingList/rank_studyNums",

      rank360_member: "rankingList/rank360_member",

      hrPrice: "rankingList/hrPrice",
      daliyPrice: "rankingList/daliyPrice",
      travelPrice: "rankingList/travelPrice",
      leaveRank: "rankingList/leaveRank",
      workPlacePercent: "rankingList/workPlacePercent",
    }),
    async pageInit() {
      this.moreLoadingPd = false;
      this.subLoading = true;
      this.paramsArray = [];
      this.mySwiper_list = [];

      if (this.rankTopSelected == 1) {
        for (let i = 0; i < 3; i++) {
          this.paramsArray.push({
            current: this.pageNum,
            size: this.pageSize,
            sorted: "desc",
            colorRank: "red",
          });

          this.mySwiper_list.push({ list: [] });
        }

        this.$set(this.mySwiper_list[0], "listType", "rank_workYear");
        this.$set(this.mySwiper_list[1], "listType", "rank_studyNums");
        this.$set(this.mySwiper_list[2], "listType", "rank_inPush");

        let res_inPush = await this.rank_inPush(this.paramsArray[2]);
        let res_workYear = await this.rank_workYear(this.paramsArray[0]);
        let res_studyNums = await this.rank_studyNums(this.paramsArray[1]);

        this.subLoading = false;

        this.mySwiper_list.forEach((val, e) => {
          if (val.listType == "rank_workYear") {
            val.title = this.$t("rank['rankList2_titile_member_develop']");
            this.$set(val, "numType", "year");

            val.list =
              res_workYear.data && res_workYear.data.data
                ? res_workYear.data.data.records
                : [];
          }
          if (val.listType == "rank_studyNums") {
            val.title = this.$t("rank['rankList3_titile_member_develop']");
            this.$set(val, "numType", "time");

            val.list =
              res_studyNums.data && res_studyNums.data.data
                ? res_studyNums.data.data.records
                : [];
          }
          if (val.listType == "rank_inPush") {
            val.title = this.$t("rank['rankList1_titile_member_develop']");
            this.$set(val, "numType", "person");

            val.list =
              res_inPush.data && res_inPush.data.data
                ? res_inPush.data.data.records
                : [];
          }
        });

        this.moreLoadingPd = true;
      } else if (this.rankTopSelected == 2) {
        /*for (let i = 0; i < 6; i++) {
          let params360 = {
            current: this.pageNum,
            size: this.pageSize,
            rankType: i + 1,
            sorted: "desc",
          };

          this.paramsArray.push(params360);

          this.mySwiper_list.push({ list: [] });
        }

        for (let i = 0; i < 6; i++) {
          let params360 = {
            current: this.pageNum,
            size: this.pageSize,
            rankType: i + 1,
            sorted: "desc",
          };

          let res360Member = await this.rank360_member(params360);
          this.mySwiper_list[i].list =
            res360Member.data && res360Member.data.data
              ? res360Member.data.data.records
              : [];
        }

        this.subLoading = false;

        this.mySwiper_list[0].title = this.$t(
          "rank['rankList1_titile_com360_member']"
        );
        this.mySwiper_list[1].title = this.$t(
          "rank['rankList2_titile_com360_member']"
        );
        this.mySwiper_list[2].title = this.$t(
          "rank['rankList3_titile_com360_member']"
        );
        this.mySwiper_list[3].title = this.$t(
          "rank['rankList4_titile_com360_member']"
        );
        this.mySwiper_list[4].title = this.$t(
          "rank['rankList5_titile_com360_member']"
        );
        this.mySwiper_list[5].title = this.$t(
          "rank['rankList6_titile_com360_member']"
        );

        this.mySwiper_list.forEach((val, e) => {
          if (e == 0) {
            this.$set(val, "numType", "time");
          }
          if (e == 1) {
            this.$set(val, "numType", "score");
          }
          if (e == 2) {
            this.$set(val, "numType", "score");
          }
          if (e == 3) {
            this.$set(val, "numType", "time");
          }
          if (e == 4) {
            this.$set(val, "numType", "time");
          }
          if (e == 5) {
            this.$set(val, "numType", "zNum");
          }
        });

        this.moreLoadingPd = true;*/
      } else {
        for (let i = 0; i < 10; i++) {
          if (i < 4) {
            this.paramsArray.push({
              current: this.pageNum,
              size: this.pageSize,
              sorted: "desc",
              colorRank: "red",
            });
          } else if (i == 4) {
            this.paramsArray.push({
              current: this.pageNum,
              size: this.pageSize,
              sorted: "asc",
              colorRank: "red",
            });
          } else {
            this.paramsArray.push({
              current: this.pageNum,
              size: this.pageSize,
              sorted: "desc",
              colorRank: "black",
            });
          }

          this.mySwiper_list.push({ list: [] });
        }

        this.$set(this.mySwiper_list[0], "listType", "res_workPlacePercent");
        this.$set(this.mySwiper_list[1], "listType", "res_meet");
        this.$set(this.mySwiper_list[2], "listType", "res_inCountry");
        this.$set(this.mySwiper_list[3], "listType", "res_outCountry");
        this.$set(this.mySwiper_list[4], "listType", "res_leaveRank");

        this.$set(this.mySwiper_list[5], "listType", "res_travelPrice");
        this.$set(this.mySwiper_list[6], "listType", "res_phoneMoney");
        this.$set(this.mySwiper_list[7], "listType", "res_edcMoney");
        this.$set(this.mySwiper_list[8], "listType", "res_print");
        this.$set(this.mySwiper_list[9], "listType", "res_meetCancel");

        // this.$set(this.mySwiper_list[1], "listType", "res_taxi");

        let res_workPlacePercent = await this.workPlacePercent(
          this.paramsArray[0]
        );

        let res_meet = await this.rank_meetTime(this.paramsArray[1]);

        let res_inCountry = await this.rank_travelInCountry(
          this.paramsArray[2]
        );
        let res_outCountry = await this.rank_travelOutCountry(
          this.paramsArray[3]
        );
        let res_leaveRank = await this.leaveRank(this.paramsArray[4]);

        let res_travelPrice = await this.travelPrice(this.paramsArray[5]);
        let res_phoneMoney = await this.daliyPrice(this.paramsArray[6]);
        let res_edcMoney = await this.hrPrice(this.paramsArray[7]);

        let res_print = await this.rank_printNum(this.paramsArray[8]);
        let res_meetCancel = await this.rank_meetCancel(this.paramsArray[9]);
        //let res_taxi = await this.rank_taxi(this.paramsArray[3]);

        this.subLoading = false;

        this.mySwiper_list.forEach((val, e) => {
          if (val.listType == "res_meet") {
            val.title = this.$t("rank['rankList1_titile_member']");
            this.$set(val, "numType", "hour");

            val.list =
              res_meet.data && res_meet.data.data
                ? res_meet.data.data.records
                : [];
          }

          if (val.listType == "res_print") {
            val.title = this.$t("rank['rankList2_titile_member']");
            this.$set(val, "numType", "time");

            val.list =
              res_print.data && res_print.data.data
                ? res_print.data.data.records
                : [];
          }

          if (val.listType == "res_meetCancel") {
            val.title = this.$t("rank['rankList3_titile_member']");
            this.$set(val, "numType", "time");

            val.list =
              res_meetCancel.data && res_meetCancel.data.data
                ? res_meetCancel.data.data.records
                : [];
          }

          /*if (val.listType == "res_taxi") {
            val.title = this.$t("rank['rankList4_titile_member']");
            this.$set(val, "numType", "money");
            val.list =
              res_taxi.data && res_taxi.data.data
                ? res_taxi.data.data.records
                : [];
          }*/

          if (val.listType == "res_edcMoney") {
            val.title = this.$t("rank['rankList5_titile_member']");
            this.$set(val, "numType", "money");
            val.list =
              res_edcMoney.data && res_edcMoney.data.data
                ? res_edcMoney.data.data.records
                : [];
          }
          if (val.listType == "res_phoneMoney") {
            val.title = this.$t("rank['rankList6_titile_member']");
            this.$set(val, "numType", "money");
            val.list =
              res_phoneMoney.data && res_phoneMoney.data.data
                ? res_phoneMoney.data.data.records
                : [];
          }
          if (val.listType == "res_inCountry") {
            val.title = this.$t("rank['rankList7_titile_member']");
            this.$set(val, "numType", "time");
            val.list =
              res_inCountry.data && res_inCountry.data.data
                ? res_inCountry.data.data.records
                : [];
          }
          if (val.listType == "res_outCountry") {
            val.title = this.$t("rank['rankList8_titile_member']");
            this.$set(val, "numType", "time");
            val.list =
              res_outCountry.data && res_outCountry.data.data
                ? res_outCountry.data.data.records
                : [];
          }
          if (val.listType == "res_travelPrice") {
            val.title = this.$t("rank['rankList9_titile_member']");
            this.$set(val, "numType", "money");
            val.list =
              res_travelPrice.data && res_travelPrice.data.data
                ? res_travelPrice.data.data.records
                : [];
          }
          if (val.listType == "res_leaveRank") {
            val.title = this.$t("rank['rankList10_titile_member']");
            this.$set(val, "numType", "day");
            val.list =
              res_leaveRank.data && res_leaveRank.data.data
                ? res_leaveRank.data.data.records
                : [];
          }
          if (val.listType == "res_workPlacePercent") {
            val.title = this.$t("rank['rankList11_titile_member']");
            this.$set(val, "numType", "percent");

            val.list =
              res_workPlacePercent.data && res_workPlacePercent.data.data
                ? res_workPlacePercent.data.data.records
                : [];
          }
        });

        setTimeout(() => {
          this.moreLoadingPd = true;
        }, 200);
      }

      this.$forceUpdate();
    },
    qes_click(index, item) {
      let params = {
        index: index,
        item: item,
      };
      this.$emit("qes_click", params);
    },
    async sortThis(index, typeMore) {
      this.moreLoadingPd = false;

      if (!typeMore) {
        this.paramsArray[index].sorted === "asc"
          ? (this.paramsArray[index].sorted = "desc")
          : (this.paramsArray[index].sorted = "asc");

        this.paramsArray[index].colorRank === "black"
          ? (this.paramsArray[index].colorRank = "red")
          : (this.paramsArray[index].colorRank = "black");

        this.paramsArray[index].current = 1;
      } else {
        this.paramsArray[index].current = this.paramsArray[index].current + 1;
      }

      let params = this.paramsArray[index];

      let res = {};

      if (this.rankTopSelected == 1) {
        if (this.mySwiper_list[index].listType == "rank_inPush") {
          res = await this.rank_inPush(params);
        } else if (this.mySwiper_list[index].listType == "rank_workYear") {
          res = await this.rank_workYear(params);
        } else if (this.mySwiper_list[index].listType == "rank_studyNums") {
          res = await this.rank_studyNums(params);
        } else {
        }
      } else if (this.rankTopSelected == 2) {
        // res = await this.rank360_member(params);
      } else {
        if (this.mySwiper_list[index].listType == "res_meet") {
          res = await this.rank_meetTime(params);
        } else if (this.mySwiper_list[index].listType == "res_print") {
          res = await this.rank_printNum(params);
        } else if (this.mySwiper_list[index].listType == "res_meetCancel") {
          res = await this.rank_meetCancel(params);
        } /*else if (this.mySwiper_list[index].listType == "res_taxi") {
          res = await this.rank_taxi(params);
        }*/ else if (this.mySwiper_list[index].listType == "res_edcMoney") {
          res = await this.hrPrice(params);
        } else if (this.mySwiper_list[index].listType == "res_phoneMoney") {
          res = await this.daliyPrice(params);
        } else if (this.mySwiper_list[index].listType == "res_inCountry") {
          res = await this.rank_travelInCountry(params);
        } else if (this.mySwiper_list[index].listType == "res_outCountry") {
          res = await this.rank_travelOutCountry(params);
        } else if (this.mySwiper_list[index].listType == "res_travelPrice") {
          res = await this.travelPrice(params);
        } else if (this.mySwiper_list[index].listType == "res_leaveRank") {
          res = await this.leaveRank(params);
        } else if (
          this.mySwiper_list[index].listType == "res_workPlacePercent"
        ) {
          res = await this.workPlacePercent(params);
        } else {
        }
      }

      let dataResLs = res.data && res.data.data ? res.data.data.records : [];

      if (!typeMore) {
        this.mySwiper_list[index].list = dataResLs;

        this.$refs.rankScrollRef[index].scrollTo(0, 0);
      } else {
        this.mySwiper_list[index].list =
          this.mySwiper_list[index].list.concat(dataResLs);
      }

      if (dataResLs && dataResLs.length > 0) {
        this.moreLoadingPd = true;
      }

      this.$forceUpdate();
    },
    loadMoreList() {
      if (this.moreLoadingPd) {
        this.paramsArray.forEach((val, e) => {
          this.sortThis(e, "more");
        });
      }
    },
  },

  mounted() {
    this.pageInit();
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-rankingList--member {
  width: 100%;
  padding: 0 0 toPad(16) toPad(40);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .edp-rankingList--member__blank {
    width: 100%;
    height: toPad(20);
    overflow: hidden;
    background: transparent;
    flex-shrink: 0;
  }

  .edp-rankingList--member__content {
    flex-basis: 100%;
    overflow: hidden;
  }

  .swiper-container {
    height: 100%;
  }
  .edp-rank__swiper__slide {
    width: 100%;
    height: 100%;
  }
  .edp-rank__swiper__slide__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 toPad(4) toPad(13) rgba(230, 236, 244, 0.6);
    border-radius: toPad(10);

    .ersItem-title {
      position: relative;
      display: flex;
      width: 100%;
      padding: toPad(20) toPad(20) toPad(24) toPad(20);
      align-items: flex-start;
      line-height: 1;
      flex-shrink: 0;
      z-index: 0;
      font-family: JLREmeric;

      background: linear-gradient(
        180deg,
        rgba(97, 191, 180, 0.31) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: toPad(10) toPad(10) 0 0;

      &.asc-title {
        background: linear-gradient(
          180deg,
          rgba(130, 130, 130, 0.28) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .ersItem-title__label {
        display: flex;
        flex-basis: 100%;
        justify-content: flex-start;
        line-height: 1.4;
      }

      label {
        font-size: toPad(18);
        font-weight: 600;
      }
      i {
        display: inline-block;
        width: toPad(16);
        height: toPad(16);
        cursor: pointer;
        background: url("~@/src/assets/rank/question.png") no-repeat center
          center;
        background-size: 100% 100%;
        margin-left: toPad(10);
        margin-right: toPad(10);
        margin-top: toPad(6);
        flex-shrink: 0;
      }
      em {
        display: inline-block;
        flex-shrink: 0;
        text-align: right;
        font-size: toPad(14);
        padding-right: toPad(20);
        min-height: toPad(16);
        background: url("~@/src/assets/rank/sort.png") no-repeat right center;
        background-size: auto toPad(16);
        cursor: pointer;
        font-style: normal;
        margin-top: toPad(6);
      }
    }
  }

  .ersItem-commingSoon {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    font-weight: bold;
    font-size: toPad(16);
    color: #666;
  }
  .ersItem-content {
    position: relative;
    flex-basis: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
    padding-top: toPad(6);
  }
  .ersItem-content__item {
    display: flex;
    max-width: 100%;
    margin: 0 toPad(20);
    align-items: center;
    padding: toPad(15) 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.rankItemFirst {
      .ersItem-content__imgContent {
        img {
          border: toPad(2) solid #feb64e;
        }
      }
    }
  }
  .ersItem-content__sortNum {
    width: toPad(34);
    font-size: toPad(18);
    flex-shrink: 0;
  }
  .ersItem-content__imgContent {
    width: toPad(60);
    height: toPad(60);
    flex-shrink: 0;
    margin-right: toPad(13);
    position: relative;
    background: #eee;
    border-radius: 100%;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      z-index: 1;
    }
    .ersItem-content__imgContent__first {
      border: 0 !important;
      width: toPad(25) !important;
      height: auto !important;
      position: absolute !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      top: toPad(-20);
      border-radius: 0 !important;
      z-index: 0;
    }
  }
  .ersItem-content__detail {
    flex-basis: 100%;
    overflow: hidden;

    em {
      display: block;
      width: 100%;
      font-style: normal;
      font-size: toPad(18);
      line-height: 1.4;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    p {
      display: block;
      width: 100%;
      font-size: toPad(13);
      opacity: 0.5;
      line-height: 1.4;
      padding-top: toPad(3);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .ersItem-content__numDetail {
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    line-height: 1;

    em {
      font-size: toPad(26);
      color: #61bfb4;
      font-family: JLREmeric;
      font-style: normal;
      font-weight: bold;
    }
    p {
      position: relative;
      font-size: toPad(13);
      opacity: 0.5;
      top: toPad(-4);
    }
  }
}
</style>
