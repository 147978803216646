<template>
  <div class="rankCate">
    <ul>
      <li
        v-for="(item, index) in pageList"
        :key="'rankTopItem' + index"
        :class="{ selected: item.selected }"
        @click="changeRankType(item, index)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "rankCate",
  props: ["list"],
  data() {
    return {
      pageList: [],
    };
  },
  methods: {
    changeRankType(item, index) {
      this.pageList.forEach((val, e) => {
        e == index ? (val.selected = true) : (val.selected = false);
      });

      this.$store.commit("rankingList/set_memberDepartSelected", item.type);

      this.$forceUpdate();
    },
  },
  mounted() {
    setTimeout(() => {
      this.pageList = this.list;

      this.pageList.forEach((val, e) => {
        if (e == 0) {
          val.selected = true;
        } else {
          val.selected = false;
        }
      });
    }, 0);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.rankCate {
  width: 100%;
  padding: toPad(15) toPad(40);
  overflow: hidden;

  li {
    display: inline-block;
    padding: toPad(13) toPad(16);
    line-height: 1;
    margin-right: toPad(16);
    cursor: pointer;
    font-size: toPad(14);
    border-radius: toPad(6);

    &.selected {
      background: rgba(109, 182, 147, 0.17);
      font-weight: 600;
    }
  }
}
</style>
